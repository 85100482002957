<template>
  <v-container
    v-if="registeradd"
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-autocomplete
          v-model="formacionesUsuariasLocal"
          :items="formaciones"
          label="Formaciones"
          chips
          clearable
          deletable-chips
          multiple
          item-text="descripcion"
          item-value="id"
        />
      </v-col>
      <v-btn
        class="mr-4"
        color="primary"
        :loading="loading"
        style="margin-top: 30px;"
        @click="submitFormaciones"
      >
        Añadir Formaciones
      </v-btn>
    </v-row>
    <!-- <v-expansion-panels
      v-model="panel"
      :readonly="true"
      multiple
    >
      <v-expansion-panel>
        <v-expansion-panel-header>Listado Formación</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            :headers="headersPreform"
            :items="formaciones"
            :search="search"
            :loading="loadingTable"
            class="elevation-1"
          >
            <template v-slot:item.acciones="{ item }">
              <v-icon
                small
                class="mr-2"
                color="success"
                @click="editItem(item)"
              >
                mdi-pencil-outline
              </v-icon>
              <v-icon
                small
                color="error"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Añadir Formación</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form
            ref="formPref"
            v-model="valid"
            lazy-validation
          >
            <input
              v-model="editedItemForm.id"
              type="hidden"
            >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-select
                  v-model="editedItemForm.tipo"
                  :items="itemsPreformacion"
                  label="Tipo"
                  :rules="[v => !!v || 'Campo requerido']"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-select
                  v-model="editedItemForm.idioma"
                  :items="itemsIdiomas"
                  label="Idioma"
                  :rules="[v => !!v || 'Campo requerido']"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-menu
                  ref="menuFn"
                  v-model="menuFn"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItemForm.fecha"
                      label="Fecha"
                      hint="DD/MM/YYYY"
                      persistent-hint
                      prepend-icon="mdi-calendar-month"
                      v-bind="attrs"
                      :rules="[v => !!v || 'Campo requerido']"
                      @blur="dateFn = parseDate(editedItemForm.fecha)"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="dateFn"
                    locale="es"
                    show-current="false"
                    @input="menuFn = false"
                  />
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="editedItemForm.descripcion"
                  label="Descripción"
                  :rules="[v => !!v || 'Campo requerido']"
                />
              </v-col>
            </v-row>
            <v-btn
              class="mr-4"
              color="primary"
              :loading="loading"
              @click="submit"
            >
              Añadir
            </v-btn>
            <v-btn
              color="#F6F6F6"
              style="color:black;"
              @click="clear"
            >
              Limpiar
            </v-btn>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels> -->
  </v-container>
</template>

<script>
  import Vue from 'vue'
  export default {
    name: 'Formaciones',
    props: {
      editedItemForm: {
        type: Object,
        required: true,
      },
      usuariaId: {
        type: Number,
        required: false,
        default: 0,
      },
      formacionesUsuarias: {
        type: Array,
        required: true,
      },
    },
    data: vm => {
      return {
        formacionesUsuariasLocal: vm.formacionesUsuarias,
        valid: true,
        panel: [0, 1],
        fnDesde: null,
        fnHasta: null,
        dateFn: new Date().toISOString().substr(0, 10),
        dateFnDesde: new Date().toISOString().substr(0, 10),
        dateFnHasta: new Date().toISOString().substr(0, 10),
        menuFn: false,
        menuFnDesde: false,
        menuFnHasta: false,
        search: '',
        snackbar: false,
        colorSnak: 'info',
        text: '',
        timeout: 3000,
        formacion: [],
        preformacion: [],
        itemsPreformacion: [
          'Formaciones',
          'Cursos y Talleres',
        ],
        itemsIdiomas: [
          'Inglés',
          'Español',
        ],
        headersForm: [
          {
            text: 'Formación',
            align: 'left',
            sortable: true,
            value: 'nombre',
          },
          {
            text: 'Acciones',
            value: 'acciones',
            sortable: false,
          },
        ],
        headersPreform: [
          {
            text: 'Tipo',
            align: 'left',
            sortable: true,
            value: 'tipo',
          },
          {
            text: 'Idioma',
            align: 'left',
            sortable: true,
            value: 'idioma',
          },
          {
            text: 'Fecha',
            align: 'left',
            sortable: true,
            value: 'fecha',
          },
          {
            text: 'Descripción',
            align: 'left',
            sortable: true,
            value: 'descripcion',
          },
          {
            text: 'Acciones',
            value: 'acciones',
            sortable: false,
          },
        ],
        emailRules: [
          v => !!v || 'EL correo electrónico es requerido',
          v => /.+@.+\..+/.test(v) || 'Ingresa un correo valido',
        ],
        // editedItemForm: {
        //   id: null,
        //   tipo: '',
        //   idioma: '',
        //   fecha: '',
        //   descripcion: '',
        // },
      }
    },
    computed: {
      registeradd: {
        get () {
          return this.$store.state.registros.registeradd
        },
      },
      // formaciones: {
      //   get () {
      //     return this.$store.state.registros.formaciones
      //   },
      // },
      loading: {
        get () {
          return this.$store.state.registros.loadingFormSave
        },
      },
      loadingTable: {
        get () {
          return this.$store.state.registros.loadingForm
        },
      },
      formaciones: {
        get () {
          return this.$store.state.formaciones.formaciones
        },
      },
    },
    watch: {
      dateFn (val) {
        this.editedItemForm.fecha = this.formatDate(this.dateFn)
      },
      dateFnDesde (val) {
        this.fnDesde = this.formatDate(this.dateFnDesde)
      },
      dateFnHasta (val) {
        this.fnHasta = this.formatDate(this.dateFnHasta)
      },
    },
    /* mounted () {
      console.log('User ID: ' + this.usuariaId)
    }, */
    methods: {
      editItem (item) {
        this.editedItemForm = Object.assign({}, item)
      },
      deleteItem (item) {
        this.$confirm('¿Está seguro que desea eliminar el registro?', {
          buttonTrueText: 'Si',
          buttonFalseText: 'No',
        })
          .then(res => {
            if (res) {
              this.$store.dispatch('registros/deleteFormacion', item.id)
                .then((response) => {
                  Vue.notify({
                    group: 'loggedIn',
                    text: response.data.success,
                    type: 'teal accent-3',
                    duration: 5000,
                  })
                })
                .catch(err => {
                  // eslint-disable-next-line
                  console.log(err)
                  Vue.notify({
                    group: 'loggedIn',
                    text: 'No se pudo eliminar en este momento',
                    type: 'red accent-2',
                    duration: 5000,
                  })
                },
                )
            }
          })
      },
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null
        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      /* submit () {
        if (this.$refs.formPref.validate()) {
          const data = {
            id: this.editedItemForm.id,
            fecha: this.editedItemForm.fecha,
            idioma: this.editedItemForm.idioma,
            descripcion: this.editedItemForm.descripcion,
            tipo: this.editedItemForm.tipo,
            usuaria: this.usuariaId,
          }

          if (this.usuariaId !== 0) {
            this.$store.dispatch('registros/addFormaciones', data)
              .then((response) => {
                Vue.notify({
                  group: 'loggedIn',
                  text: response.data.success,
                  type: 'teal accent-3',
                  duration: 5000,
                })
                this.$store.dispatch('registros/getFormaciones', data)
                this.clear()
              })
              .catch(err => {
                // eslint-disable-next-line
                console.log(err)
                Vue.notify({
                  group: 'loggedIn',
                  text: 'No se pudo guardar en este momento',
                  type: 'red accent-2',
                  duration: 5000,
                })
              },
              )
          } else {
            Vue.notify({
              group: 'loggedIn',
              text: 'Debe crear la nueva usuaria para asociarle una formación',
              type: 'red accent-2',
              duration: 5000,
            })
          }
        }
      }, */
      submitFormaciones () {
        if (this.usuariaId) {
          this.$store.commit('registros/setLoaderFormacionSave')
          this.completarSubmitFormaciones()
        } else {
          this.$emit('submit-usuaria')
          this.$store.commit('registros/setLoaderFormacionSave')

          setTimeout(() => {
            const nuevoIdUsuaria = localStorage.getItem('usuariaId')
            console.log(nuevoIdUsuaria)
            if (nuevoIdUsuaria === null || nuevoIdUsuaria === undefined) {
              Vue.notify({
                group: 'loggedIn',
                text: 'No ha sido posible dar de alta a la usuaria',
                type: 'red accent-2',
                duration: 5000,
              })
            } else {
              this.usuariaId = nuevoIdUsuaria
              this.completarSubmitFormaciones()
            }
          }, 3000)
        }
      },
      completarSubmitFormaciones () {
        var formaciones = {
          id: this.usuariaId,
          formaciones: this.formacionesUsuariasLocal,
        }
        this.$store.dispatch('registros/formacionesUsuarias', formaciones)
          .then((response) => {
            Vue.notify({
              group: 'loggedIn',
              text: response.data.success,
              type: 'teal accent-3',
              duration: 5000,
            })
          })
          .catch(err => {
            // eslint-disable-next-line
            console.log(err)
            Vue.notify({
              group: 'loggedIn',
              text: 'No se pudo guardar en este momento',
              type: 'red accent-2',
              duration: 5000,
            })
          })
      },
      clear () {
        this.$refs.formPref.reset()
      },
    },

  }
</script>

<style scoped>
.v-card {
  margin-top: 0px;
  margin-bottom: 0px;
}
.v-expansion-panel-header {
    background-color: aliceblue;
}

i.v-icon.notranslate.mdi.mdi-chevron-down.theme--light {
  display: none !important;
}
</style>
