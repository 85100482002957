<template>
  <div style="display: flex; flex-wrap: wrap;">
    <div
      class="divFirmaWacom"
    >
      <div id="signatureDiv">
        Firma del usuario:<br>
        <img
          id="signatureImage"
          :src="imagen"
        >
      </div>
    </div>
    <div style="margin-top: 10px; margin-bottom: 20px;">
      <input
        id="signButton"
        type="button"
        value="Iniciar firma desde STU"
        onClick="tabletDemo()"
        class="BtnIniciarFirma"
      >
      <v-btn
        color="red"
        @click="confirmarFirma"
      >
        Confirmar firma
      </v-btn>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  export default {
    name: 'WacomSdk',
    props: {
      firma: {
        type: String,
        default: null,
      },
    },
    data: vm => {
      return {
        imagen: '#',
        imgDefault: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAScAAACrCAMAAAATgapkAAAAD1BMVEX///8AAADs7OwIBgjb29vJ8HY5AAABZ0lEQVR4nO3QARHAIAwAMQr417ybAl5AIiHrDG9nzd283FmzFy/bU+Kp8dR4ajw1nhpPjafGU+Op8dR4ajw1nhpPjafGU+Op8dR4ajw1nhpPjafGU+Op8dR4ajw1nhpPjafGU+Op8dR4ajw1nhpPjafGU+Op8dR4ajw1nhpPjafGU+Op8dR4ajw1nhpPjafGU+Op8dR4ajw1nhpPjafGU+Op8dR4ajw1nhpPjafGU+Op8dR4ajw1nhpPjafGU+Op8dR4ajw1nhpPjafGU+Op8dR4ajw1nhpPjafGU+Op8dR4ajw1nhpPjafGU+Op8dR4ajw1nhpPjafGU+Op8dR4ajw1nhpPjafGU+Op8dR4ajw1nhpPjafGU+Op8dR4ajw1nhpPjafGU+Op8dR4ajw1nhpPjafGU+Op8dR4ajw1nhpPjafGU+Op8dR4ajw1nhpPjafGU+Op8dR4ajw1npr/6W5e7qwzvJ0PHUQK4Qa42UoAAAAASUVORK5CYII=',
      }
    },
    created () {
      if (this.firma === null || this.firma === '') {
        this.imagen = this.imgDefault
      } else {
        this.imagen = this.firma
      }
    },
    mounted () {
      const q = document.createElement('script')
      q.setAttribute('src', process.env.BASE_URL + 'js/stu-wacom/q.js')
      document.head.appendChild(q)

      const wgssStuSdk = document.createElement('script')
      wgssStuSdk.setAttribute('src', process.env.BASE_URL + 'js/stu-wacom/wgssStuSdk.js')
      document.head.appendChild(wgssStuSdk)

      const main = document.createElement('script')
      main.setAttribute('src', process.env.BASE_URL + 'js/stu-wacom/main.js')
      document.head.appendChild(main)
    },
    methods: {
      /**
       * Este paso se necesita puesto que no podemos relacionar el valor de base64 que construye
       * el SDK de Wacom. Usndo este método tomamos el valor de forma directa y ahí sí lo podemos
       * relacionar a una variable
       */
      confirmarFirma () {
        const myImageElement = document.getElementById('signatureImage')
        const src = myImageElement.getAttribute('src')

        if (src === '' || src === '#' || src === this.imgDefault) {
          Vue.notify({
            group: 'loggedIn',
            text: 'Debe realizar la firma, antes de poder Confirmar',
            type: 'red accent-2',
            duration: 5000,
          })
        } else {
          this.$emit('setFirmaWacom', src)
          Vue.notify({
            group: 'loggedIn',
            text: 'Firma confirmada, puede continuar',
            type: 'teal accent-5',
            duration: 5000,
          })
        }
      },
    },
  }
</script>

<style scoped>
.button {
  position:absolute;
}

#signatureBox {
  display: none;
}
#modal-background {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: .50;
    -webkit-opacity: .5;
    -moz-opacity: .5;
    filter: alpha(opacity=50);
    z-index: 1000;
}

#signatureWindow {
    box-shadow: 0 0 20px 0 #222;
    -webkit-box-shadow: 0 0 20px 0 #222;
    -moz-box-shadow: 0 0 20px 0 #222;
    display: none;
    padding: 0;
    position: absolute;
    z-index: 1000;
}

#modal-background.active, #signatureWindow.active {
    display: block;
}

#myCanvas {
  width:100%;
  height:100%;
}
/*
#modal-background.active, #myCanvas.active {
    display: block;
}*/
/*
#myCanvas canvas {
  width:100%;
  height:100%;
}*/

#signatureDiv {
  float:left;
  margin-right:15px;
}

#signatureImage {
  width:400px;
  height:250px;
  border:1px solid black;
  display:block;
}

#wgssSTU {
  width:0;
  height:0;
}

.thirdPartyLicenses {
  display:none;
  font-size:8pt;
}

.divFirmaWacom{
  position: relative;
}

.BtnIniciarFirma{
  padding: 12px 30px;
  font: 11.9px;
  font-family: sans-serif;
  background-color: #4caf50;
  color: white;
  border-radius: 5px;
  margin-right: 5px;
}
</style>
