<template>
  <v-container
    v-if="registeradd"
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-expansion-panels
      v-model="panel"
      :readonly="true"
      multiple
    >
      <v-expansion-panel>
        <v-expansion-panel-header>Listado Intervención</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            :headers="headersIntervencion"
            :items="intervenciones"
            :search="search"
            :loading="loadingTable"
            class="elevation-1"
          >
            <template v-slot:item.acciones="{ item }">
              <v-icon
                small
                class="mr-2"
                color="success"
                @click="editItem(item)"
              >
                mdi-pencil-outline
              </v-icon>
              <v-icon
                small
                color="error"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Añadir Intervención</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form
            ref="formIntervencion"
            v-model="valid"
            lazy-validation
          >
            <input
              v-model="editedItemInterv.id"
              type="hidden"
            >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-select
                  v-model="editedItemInterv.tipo"
                  :items="itemsIntervencion"
                  label="Tipo"
                  :rules="[v => !!v || 'Campo requerido']"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-menu
                  ref="menuFn"
                  v-model="menuFn"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItemInterv.fecha"
                      label="Fecha"
                      hint="DD/MM/YYYY"
                      persistent-hint
                      prepend-icon="mdi-calendar-month"
                      v-bind="attrs"
                      :rules="[v => !!v || 'Campo requerido']"
                      @blur="dateFn = parseDate(editedItemInterv.fecha)"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="dateFn"
                    locale="es"
                    show-current="false"
                    @input="menuFn = false"
                  />
                </v-menu>
              </v-col>
              <v-col
                v-if="editedItemInterv.tipo === 'Entrevista'"
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="editedItemInterv.entrevista"
                  label="Persona o Empresa donde será la entrevista"
                  :rules="[v => !!v || 'Campo requerido']"
                />
              </v-col>
            </v-row>
            <v-row v-if="editedItemInterv.tipo === 'Entrevista'">
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-checkbox
                  v-model="editedItemInterv.empleo"
                  label="Obtencion de empleo"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="editedItemInterv.descripcion"
                  label="Descripción"
                  :rules="[v => !!v || 'Campo requerido']"
                />
              </v-col>
            </v-row>
            <v-btn
              class="mr-4"
              color="primary"
              :loading="loading"
              @click="submit"
            >
              Añadir
            </v-btn>
            <v-btn
              color="#F6F6F6"
              style="color:black;"
              @click="clear"
            >
              Limpiar
            </v-btn>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
  import Vue from 'vue'
  export default {
    name: 'Intervenciones',
    props: {
      editedItemInterv: {
        type: Object,
        required: true,
      },
      usuariaId: {
        type: Number,
        required: true,
      },
    },
    data: vm => {
      return {
        panel: [0, 1],
        entrevista: false,
        valid: true,
        dateFn: new Date().toISOString().substr(0, 10),
        menuFn: false,
        search: '',
        snackbar: false,
        colorSnak: 'info',
        text: '',
        timeout: 3000,
        intervencion: [],
        clientes: [],
        itemsIntervencion: [
          'Entrevista',
          'Derivación',
          'Seguimiento',
          // 'Oferta Empleo',
        ],
        headersIntervencion: [
          {
            text: 'Tipo',
            align: 'left',
            sortable: true,
            value: 'tipo',
          },
          {
            text: 'Fecha',
            align: 'left',
            sortable: true,
            value: 'fecha',
          },
          {
            text: 'Descripción',
            align: 'left',
            sortable: true,
            value: 'descripcion',
          },
          {
            text: 'Acciones',
            value: 'acciones',
            sortable: false,
          },
        ],
        emailRules: [
          v => !!v || 'EL correo electrónico es requerido',
          v => /.+@.+\..+/.test(v) || 'Ingresa un correo valido',
        ],
        // editedItemInterv: {
        //   id: null,
        //   tipo: '',
        //   fecha: '',
        //   descripcion: '',
        //   entrevista: null,
        //   empleo: false,
        // },
      }
    },
    computed: {
      registeradd: {
        get () {
          return this.$store.state.registros.registeradd
        },
      },
      intervenciones: {
        get () {
          return this.$store.state.registros.intervenciones
        },
      },
      loading: {
        get () {
          return this.$store.state.registros.loadingIntervSave
        },
      },
      loadingTable: {
        get () {
          return this.$store.state.registros.loadingInterv
        },
      },
    },
    watch: {
      dateFn (val) {
        this.editedItemInterv.fecha = this.formatDate(this.dateFn)
      },
    },
    mounted () {
      if (this.usuariaId) {
        this.$store.dispatch('registros/getIntervencionesUsuariaId', this.usuariaId)
      }
    },
    methods: {
      addRegister () {
        this.$store.commit('registros/setRegister', true)
      },
      editItem (item) {
        this.editedItemInterv = Object.assign({}, item)
      },
      deleteItem (item) {
        this.$confirm('¿Está seguro que desea eliminar el registro?', {
          buttonTrueText: 'Si',
          buttonFalseText: 'No',
        })
          .then(res => {
            if (res) {
              this.$store.dispatch('registros/deleteIntervencion', item.id)
                .then((response) => {
                  this.$store.dispatch('registros/getIntervencionesUsuariaId', this.usuariaId)
                  Vue.notify({
                    group: 'loggedIn',
                    text: response.data.success,
                    type: 'teal accent-3',
                    duration: 5000,
                  })
                })
                .catch(err => {
                  // eslint-disable-next-line
                  console.log(err)
                  Vue.notify({
                    group: 'loggedIn',
                    text: 'No se pudo eliminar en este momento',
                    type: 'red accent-2',
                    duration: 5000,
                  })
                },
                )
            }
          })
      },
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null
        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      submit () {
        if (this.$refs.formIntervencion.validate()) {
          if (this.editedItemInterv.id === null) {
            if (this.usuariaId) {
              this.$store.commit('registros/setLoaderIntervencionesSave')
              this.completarSubmit()
            } else {
              this.$emit('submit-usuaria')
              this.$store.commit('registros/setLoaderIntervencionesSave')

              setTimeout(() => {
                const nuevoIdUsuaria = localStorage.getItem('usuariaId')
                console.log(nuevoIdUsuaria)
                if (nuevoIdUsuaria === null || nuevoIdUsuaria === undefined) {
                  Vue.notify({
                    group: 'loggedIn',
                    text: 'No ha sido posible dar de alta a la usuaria',
                    type: 'red accent-2',
                    duration: 5000,
                  })
                } else {
                  this.usuariaId = nuevoIdUsuaria
                  this.completarSubmit()
                }
              }, 3000)
            }
          } else {
            const data = {
              id: this.editedItemInterv.id,
              fecha: this.editedItemInterv.fecha,
              descripcion: this.editedItemInterv.descripcion,
              tipo: this.editedItemInterv.tipo,
              empleo: this.editedItemInterv.empleo,
              entrevista: this.editedItemInterv.entrevista,
              usuaria: this.usuariaId,
            }

            this.$store.dispatch('registros/updateIntervenciones', data)
              .then((response) => {
                Vue.notify({
                  group: 'loggedIn',
                  text: response.data.success,
                  type: 'teal accent-3',
                  duration: 5000,
                })
                this.$store.dispatch('registros/getIntervencionesUsuariaId', this.usuariaId)
                this.clear()
              })
              .catch(err => {
                // eslint-disable-next-line
                console.log(err)
                Vue.notify({
                  group: 'loggedIn',
                  text: 'No se pudo guardar en este momento',
                  type: 'red accent-2',
                  duration: 5000,
                })
              })
          }
        }
      },
      completarSubmit () {
        const data = {
          id: this.editedItemInterv.id,
          fecha: this.editedItemInterv.fecha,
          descripcion: this.editedItemInterv.descripcion,
          tipo: this.editedItemInterv.tipo,
          empleo: this.editedItemInterv.empleo,
          entrevista: this.editedItemInterv.entrevista,
          usuaria: this.usuariaId,
        }

        this.$store.dispatch('registros/addIntervenciones', data)
          .then((response) => {
            Vue.notify({
              group: 'loggedIn',
              text: response.data.success,
              type: 'teal accent-3',
              duration: 5000,
            })
            this.$store.dispatch('registros/getIntervencionesUsuariaId', this.usuariaId)
            this.clear()
          })
          .catch(err => {
            // eslint-disable-next-line
            console.log(err)
            Vue.notify({
              group: 'loggedIn',
              text: 'No se pudo guardar en este momento',
              type: 'red accent-2',
              duration: 5000,
            })
          })
      },
      clear () {
        this.$refs.formIntervencion.reset()
      },
      // changeIntervencion () {
      //   console.log(this.tipoIntervencion)
      //   if (this.tipoIntervencion === 'Entrevista') {
      //     this.entrevista = true
      //   } else {
      //     this.entrevista = false
      //   }
      // },
    },

  }
</script>

<style scoped>
.v-card {
  margin-top: 0px;
  margin-bottom: 0px;
}

.v-expansion-panel-header {
    background-color: aliceblue;
}
</style>
