<template>
  <v-container
    v-if="registeradd"
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-autocomplete
          v-model="ofertasUsuariasLocal"
          :items="ofertas"
          label="Ofertas"
          chips
          clearable
          deletable-chips
          multiple
          item-text="oferta"
          item-value="id"
        />
      </v-col>
      <v-btn
        class="mr-4"
        color="primary"
        :loading="loadingOfertas"
        style="margin-top: 30px;"
        @click="submitOfertas"
      >
        Añadir Ofertas
      </v-btn>
    </v-row>
    <v-expansion-panels
      v-model="panel"
      :readonly="true"
      multiple
    >
      <v-expansion-panel>
        <v-expansion-panel-header>Listado Intermediación Laboral</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            :headers="headersIntermediacion"
            :items="intermediaciones"
            :search="search"
            :loading="loadingTable"
            class="elevation-1"
          >
            <template v-slot:item.acciones="{ item }">
              <v-icon
                small
                class="mr-2"
                color="success"
                @click="editItem(item)"
              >
                mdi-pencil-outline
              </v-icon>
              <v-icon
                small
                color="error"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Añadir Intermediación Laboral</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form
            ref="formIntermediacion"
            v-model="valid"
            lazy-validation
          >
            <input
              v-model="editedItemInterm.id"
              type="hidden"
            >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-menu
                  ref="menuFn"
                  v-model="menuFn"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItemInterm.fecha"
                      label="Fecha"
                      hint="DD/MM/YYYY"
                      persistent-hint
                      prepend-icon="mdi-calendar-month"
                      v-bind="attrs"
                      :rules="[v => !!v || 'Campo requerido']"
                      @blur="dateFn = parseDate(editedItemInterm.fecha)"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="dateFn"
                    locale="es"
                    show-current="false"
                    @input="menuFn = false"
                  />
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <label>Intermediación Laboral</label>
                <v-textarea
                  v-model="editedItemInterm.intermediacion"
                  name="input-7-1"
                  label="Intermediación Laboral"
                  solo
                  :rules="[v => !!v || 'Campo requerido']"
                  auto-grow
                />
              </v-col>
            </v-row>
            <v-btn
              class="mr-4"
              color="primary"
              :loading="loading"
              @click="submit"
            >
              Añadir
            </v-btn>
            <v-btn
              color="#F6F6F6"
              style="color:black;"
              @click="clear"
            >
              Limpiar
            </v-btn>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
  import Vue from 'vue'
  export default {
    name: 'Intermediaciones',
    props: {
      editedItemInterm: {
        type: Object,
        required: true,
      },
      usuariaId: {
        type: Number,
        required: false,
        default: 0,
      },
      ofertasUsuarias: {
        type: Array,
        required: true,
      },
    },
    data: vm => {
      return {
        valid: true,
        panel: [0, 1],
        dateFn: new Date().toISOString().substr(0, 10),
        menuFn: false,
        search: '',
        snackbar: false,
        colorSnak: 'info',
        text: '',
        timeout: 3000,
        intervencion: [],
        clientes: [],
        ofertasUsuariasLocal: vm.ofertasUsuarias,
        headersIntermediacion: [
          {
            text: 'Fecha',
            align: 'left',
            sortable: true,
            value: 'fecha',
          },
          {
            text: 'Intermediación Laboral',
            align: 'left',
            sortable: true,
            value: 'intermediacion',
          },
          {
            text: 'Acciones',
            value: 'acciones',
            sortable: false,
          },
        ],
        emailRules: [
          v => !!v || 'EL correo electrónico es requerido',
          v => /.+@.+\..+/.test(v) || 'Ingresa un correo valido',
        ],
        // editedItemInterm: {
        //   id: null,
        //   fecha: '',
        //   intermediacion: '',
        // },
      }
    },
    computed: {
      registeradd: {
        get () {
          return this.$store.state.registros.registeradd
        },
      },
      intermediaciones: {
        get () {
          return this.$store.state.registros.intermediaciones
        },
      },
      loading: {
        get () {
          return this.$store.state.registros.loadingIntermSave
        },
      },
      loadingTable: {
        get () {
          return this.$store.state.registros.loadingInterm
        },
      },
      loadingOfertas: {
        get () {
          return this.$store.state.registros.loadingOfertas
        },
      },
      ofertas: {
        get () {
          return this.$store.state.ofertas.ofertas
        },
      },
    },
    watch: {
      dateFn (val) {
        this.editedItemInterm.fecha = this.formatDate(this.dateFn)
      },
    },
    mounted () {
      if (this.usuariaId) {
        this.$store.dispatch('registros/getIntermediacionUsuariaId', this.usuariaId)
      }
    },
    methods: {
      addRegister () {
        this.$store.commit('registros/setRegister', true)
      },
      editItem (item) {
        this.editedItemInterm = Object.assign({}, item)
      },
      deleteItem (item) {
        this.$confirm('¿Está seguro que desea eliminar el registro?', {
          buttonTrueText: 'Si',
          buttonFalseText: 'No',
        })
          .then(res => {
            if (res) {
              this.$store.dispatch('registros/deleteIntermediacion', item.id)
                .then((response) => {
                  this.$store.dispatch('registros/getIntermediacionUsuariaId', this.usuariaId)
                  Vue.notify({
                    group: 'loggedIn',
                    text: response.data.success,
                    type: 'teal accent-3',
                    duration: 5000,
                  })
                })
                .catch(err => {
                  // eslint-disable-next-line
                  console.log(err)
                  Vue.notify({
                    group: 'loggedIn',
                    text: 'No se pudo eliminar en este momento',
                    type: 'red accent-2',
                    duration: 5000,
                  })
                },
                )
            }
          })
      },
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null
        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      submit () {
        if (this.$refs.formIntermediacion.validate()) {
          if (this.editedItemInterm.id === null) {
            if (this.usuariaId) {
              this.$store.commit('registros/setLoaderIntermediacionesSave')
              this.completarSubmit()
            } else {
              this.$emit('submit-usuaria')
              this.$store.commit('registros/setLoaderIntermediacionesSave')

              setTimeout(() => {
                const nuevoIdUsuaria = localStorage.getItem('usuariaId')
                console.log(nuevoIdUsuaria)
                if (nuevoIdUsuaria === null || nuevoIdUsuaria === undefined) {
                  Vue.notify({
                    group: 'loggedIn',
                    text: 'No ha sido posible dar de alta a la usuaria',
                    type: 'red accent-2',
                    duration: 5000,
                  })
                } else {
                  this.usuariaId = nuevoIdUsuaria
                  this.completarSubmit()
                }
              }, 3000)
            }
          } else {
            const data = {
              id: this.editedItemInterm.id,
              fecha: this.editedItemInterm.fecha,
              intermediacion: this.editedItemInterm.intermediacion,
              usuaria: this.usuariaId,
            }

            this.$store.dispatch('registros/updateIntermediacion', data)
              .then((response) => {
                Vue.notify({
                  group: 'loggedIn',
                  text: response.data.success,
                  type: 'teal accent-3',
                  duration: 5000,
                })
                this.$store.dispatch('registros/getIntermediacionUsuariaId', this.usuariaId)
                this.clear()
              })
              .catch(err => {
                // eslint-disable-next-line
                console.log(err)
                Vue.notify({
                  group: 'loggedIn',
                  text: 'No se pudo guardar en este momento',
                  type: 'red accent-2',
                  duration: 5000,
                })
              },
              )
          }
        }
      },
      completarSubmit () {
        const data = {
          id: this.editedItemInterm.id,
          fecha: this.editedItemInterm.fecha,
          intermediacion: this.editedItemInterm.intermediacion,
          usuaria: this.usuariaId,
        }

        this.$store.dispatch('registros/addIntermediacion', data)
          .then((response) => {
            Vue.notify({
              group: 'loggedIn',
              text: response.data.success,
              type: 'teal accent-3',
              duration: 5000,
            })
            this.$store.dispatch('registros/getIntermediacionUsuariaId', this.usuariaId)
            this.clear()
          })
          .catch(err => {
            // eslint-disable-next-line
            console.log(err)
            Vue.notify({
              group: 'loggedIn',
              text: 'No se pudo guardar en este momento',
              type: 'red accent-2',
              duration: 5000,
            })
          })
      },
      submitOfertas () {
        if (this.usuariaId) {
          this.$store.commit('registros/setLoaderOfertas')
          this.completarSubmitOfertas()
        } else {
          this.$emit('submit-usuaria')
          this.$store.commit('registros/setLoaderOfertas')

          setTimeout(() => {
            const nuevoIdUsuaria = localStorage.getItem('usuariaId')
            console.log(nuevoIdUsuaria)
            if (nuevoIdUsuaria === null || nuevoIdUsuaria === undefined) {
              Vue.notify({
                group: 'loggedIn',
                text: 'No ha sido posible dar de alta a la usuaria',
                type: 'red accent-2',
                duration: 5000,
              })
            } else {
              this.usuariaId = nuevoIdUsuaria
              this.completarSubmitOfertas()
            }
          }, 3000)
        }
      },
      completarSubmitOfertas () {
        var ofertas = {
          id: this.usuariaId,
          ofertas: this.ofertasUsuariasLocal,
        }
        this.$store.dispatch('registros/ofertasUsuarias', ofertas)
          .then((response) => {
            Vue.notify({
              group: 'loggedIn',
              text: response.data.success,
              type: 'teal accent-3',
              duration: 5000,
            })
          })
          .catch(err => {
            // eslint-disable-next-line
            console.log(err)
            Vue.notify({
              group: 'loggedIn',
              text: 'No se pudo guardar en este momento',
              type: 'red accent-2',
              duration: 5000,
            })
          })
      },
      clear () {
        this.$refs.formIntermediacion.reset()
      },
      // changeIntervencion () {
      //   console.log(this.tipoIntervencion)
      //   if (this.tipoIntervencion === 'Entrevista') {
      //     this.entrevista = true
      //   } else {
      //     this.entrevista = false
      //   }
      // },
    },

  }
</script>

<style scoped>
.v-card {
  margin-top: 0px;
  margin-bottom: 0px;
}

.v-expansion-panel-header {
    background-color: aliceblue;
}
</style>
