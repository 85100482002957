<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-material-card
      color="light-blue"
      icon="mdi-account-tie"
      title="Registros"
      class="px-5 py-3"
    >
      <v-data-table
        v-if="registerlist"
        :headers="headers"
        :items="registros"
        loading-text="Cargando... Espere por favor"
        disable-pagination
        hide-default-footer
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-row
              align="center"
              class="list px-3 mx-auto"
            >
              <v-col
                cols="10"
                sm="8"
              >
                <v-text-field
                  v-model="search"
                  label="Buscar por Nombre, Tipo de documento, Número de documento, Teléfono o Fecha"
                  hint="Si desea ver nuevamente la lista completa, presione el botón de Buscar, sin ningún texto en la caja."
                />
              </v-col>
              <v-col
                cols="2"
                sm="4"
              >
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  @click="
                    page = 1;
                    initialize();
                  "
                >
                  <v-icon dark>
                    mdi-magnify
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-spacer />
            <v-btn
              color="primary"
              fab
              dark
              small
              class="mb-2 mr-3"
              @click="addRegister()"
              v-on="on"
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
            <v-btn
              color="red"
              fab
              dark
              small
              class="mb-2"
              @click="reportModalTrigger()"
            >
              <v-icon dark>
                mdi-file-chart
              </v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.fecha_inicio }}</td>
            <td>{{ item.tipo_documento }}</td>
            <td>{{ item.nro_documento }}</td>
            <td>{{ item.nombre_apellido }}</td>
            <td>{{ item.telefono }}</td>
            <td>
              <v-icon
                small
                class="mr-2"
                color="success"
                @click="editItem(item)"
              >
                mdi-pencil-outline
              </v-icon>
              <v-icon
                small
                class="mr-2"
                color="error"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
              <v-icon
                small
                class="mr-2"
                color="info"
                @click="printer(item)"
              >
                mdi-printer
              </v-icon>
            </td>
          </tr>
        </template>
        <template v-slot:header.fecha_inicio>
          <button @click="ordenFecha(sort)">
            Fecha
            <span
              v-if="sort === 'ASC'"
              class="mdi mdi-sort-calendar-ascending"
            />
            <span
              v-else
              class="mdi mdi-sort-calendar-descending"
            />
          </button>
        </template>
      </v-data-table>
      <v-row
        v-if="registerlist"
        align="center"
        class="list px-3 mx-auto"
      >
        <v-col
          cols="12"
          sm="12"
        >
          <v-row>
            <v-col
              cols="12"
              sm="10"
            >
              <v-pagination
                v-model="page"
                :length="totalPages"
                total-visible="7"
                next-icon="mdi-menu-right"
                prev-icon="mdi-menu-left"
                @input="handlePageChange"
              />
              <p class="my-2 text-center">
                Mostrando {{ showingFrom }} a {{ showingTo }} de {{ totalRecords }} registros
              </p>
            </v-col>
            <v-col
              cols="4"
              sm="2"
            >
              <v-select
                v-model="itemsPerPage"
                :items="itemsPerPageArray"
                label="Registros por página"
                @change="handleitemsPerPageChange"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <addRegister
        :edited-item="selectedItem"
        :ofertas="ofertaUsuaria"
        :formacion="formacionUsuaria"
        @initialize="initialize"
      />
      <modalReportRegister
        :show-dialog="showModalReport"
        @close-action="reportModalTrigger"
      />
    </base-material-card>
  </v-container>
</template>

<script>
  import Vue from 'vue'
  import addRegister from './addregister.vue'
  import modalReportRegister from './modalReportRegister.vue'
  export default {
    name: 'RegisterTable',
    components: {
      addRegister,
      modalReportRegister,
    },
    data: vm => {
      return {
        registros: [],
        search: '',
        snackbar: false,
        colorSnak: 'info',
        text: '',
        timeout: 3000,
        selectedItem: {},
        ofertaUsuaria: [],
        formacionUsuaria: [],
        on: false,
        headers: [
          {
            text: 'Fecha',
            align: 'left',
            sortable: false,
            filterable: false,
            value: 'fecha_inicio',
          },
          {
            text: 'Tipo ID',
            align: 'left',
            sortable: false,
            filterable: false,
            value: 'tipo_documento',
          },
          {
            text: 'Identificación',
            align: 'left',
            sortable: false,
            filterable: false,
            value: 'nro_documento',
          },
          {
            text: 'Nombre completo',
            align: 'left',
            sortable: false,
            filterable: false,
            value: 'nombre_apellido',
          },
          {
            text: 'Teléfono',
            align: 'left',
            sortable: false,
            filterable: false,
            value: 'telefono',
          },
          {
            text: 'Acciones',
            value: 'acciones',
            sortable: false,
            filterable: false,
          },
        ],
        emailRules: [
          v => !!v || 'EL correo electrónico es requerido',
          v => /.+@.+\..+/.test(v) || 'Ingresa un correo válido',
        ],
        showModalReport: false,
        // Paginacion
        sort: 'ASC',
        page: 1,
        totalPages: 0,
        itemsPerPage: 20,
        itemsPerPageArray: [20, 50, 100],
        totalRecords: 0,
        showingFrom: 0,
        showingTo: 0,
      }
    },
    computed: {
      registerlist: {
        get () {
          return this.$store.state.registros.registerlist
        },
      },
      loading: {
        get () {
          return this.$store.state.registros.loading
        },
      },
    },
    mounted () {
      this.initialize()
    },
    methods: {
      ordenFecha (value) {
        value === 'ASC' ? (this.sort = 'DESC') : (this.sort = 'ASC')
        this.initialize()
      },
      initialize () {
        // console.log('Carga de listado desde BBDD')
        const params = this.getRequestParams(
          this.search,
          this.page,
          parseInt(this.itemsPerPage),
          this.sort,
        )
        this.$store
          .dispatch('registros/getregistros', params)
          .then(response => {
            // console.log(response)
            this.registros = response.data.items
            this.totalPages = response.data.pages

            if (response.data.page === 1) {
              this.showingFrom = 1
            } else {
              this.showingFrom = ((response.data.page - 1) * this.itemsPerPage) + 1
            }
            this.showingTo = (this.showingFrom + this.itemsPerPage) - 1
            this.totalRecords = response.data.total
          })
          .catch(error => {
            console.log(error)
          })
      },
      getRequestParams (search, page, itemsPerPage, sort) {
        const params = {}
        if (search) {
          params.search = search
        }
        if (page) {
          params.page = page
        }
        if (itemsPerPage) {
          params.itemsPerPage = itemsPerPage
        }
        if (sort) {
          params.sort = sort
        }
        return params
      },
      handlePageChange (value) {
        this.page = value
        this.initialize()
      },
      handleitemsPerPageChange (size) {
        this.itemsPerPage = size
        this.page = 1
        this.initialize()
      },
      addRegister () {
        this.selectedItem = {}
        this.ofertaUsuaria = []
        this.formacionUsuaria = []
        this.$store.commit('registros/setRegister', true)
      },
      editItem (item) {
        this.selectedItem = item
        this.ofertaUsuaria = this.selectedItem.ofertasUsuarias
        this.formacionUsuaria = this.selectedItem.formacionesUsuarias
        this.$store.commit('registros/setRegister', true)
      },
      deleteItem (item) {
        this.$confirm('¿Está seguro que desea eliminar el registro?', {
          buttonTrueText: 'Si',
          buttonFalseText: 'No',
        }).then(res => {
          if (res) {
            this.$store
              .dispatch('registros/deleteUsuaria', item.id)
              .then(response => {
                this.initialize()
                Vue.notify({
                  group: 'loggedIn',
                  text: response.data.success,
                  type: 'teal accent-3',
                  duration: 5000,
                })
              })
              .catch(err => {
                // eslint-disable-next-line
              console.log(err);
                Vue.notify({
                  group: 'loggedIn',
                  text: 'No se pudo eliminar en este momento',
                  type: 'red accent-2',
                  duration: 5000,
                })
              })
          }
        })
      },
      forceFileDownload (response, nombre) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', nombre)
        document.body.appendChild(link)
        link.click()
      },
      async printer (item) {
        var response = await this.$store.dispatch('registros/printer', item.id)
        this.forceFileDownload(response, item.nombre_apellido + '_report.pdf')
      },
      reportModalTrigger () {
        this.showModalReport = !this.showModalReport
      },
    },
  }
</script>

<style scoped>
.v-card {
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>
