<template>
  <v-card
    v-if="registeradd"
    class="mb-10"
  >
    <v-row style="padding: 10px;">
      <v-spacer />
      <v-btn
        color="#F6F6F6"
        style="color:black;"
        class="mb-3"
        @click="volver"
      >
        Volver
      </v-btn>
    </v-row>
    <template>
      <v-card-title
        v-if="editedItem.nota"
        class="red"
      >
        Gestión de Usuaria
      </v-card-title>
      <v-card-title v-else>
        Gestión de Usuaria
      </v-card-title>
      <v-container fluid>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <input
            v-model="editedItem.id"
            type="hidden"
          >
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="2"
            >
              <v-menu
                ref="menuFn"
                v-model="menuFn"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="editedItem.fecha_inicio"
                    label="Fecha de Inicio"
                    hint="DD/MM/YYYY"
                    persistent-hint
                    prepend-icon="mdi-calendar-month"
                    v-bind="attrs"
                    :rules="[v => !!v || 'Fecha es requerida']"
                    @blur="dateFn = parseDate(editedItem.fecha_inicio)"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="dateFn"
                  locale="es"
                  show-current="false"
                  @input="menuFn = false"
                />
              </v-menu>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-text-field
                v-model="editedItem.nombre_apellido"
                label="Nombre completo"
                :rules="[v => !!v || 'Campo requerido']"
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="1"
            >
              <v-select
                v-model="editedItem.tipo_documento"
                :items="itemsDocumento"
                item-text="title"
                item-value="val"
                label="Tipo ID"
                :rules="[v => !!v || 'Campo requerido']"
                @change="validateDocument()"
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="2"
            >
              <v-text-field
                ref="docNumber"
                v-model="editedItem.nro_documento"
                label="Documento"
                :rules="[
                  v => !!v || 'Campo requerido',
                  () => docNumberRule(editedItem.nro_documento, editedItem.tipo_documento)
                ]"
                :error-messages="docNumberErrorMessages"
                @change="validateUserExist(/* editedItem.tipo_documento, */ editedItem.nro_documento)"
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="1"
            >
              <v-text-field
                v-model="editedItem.edad"
                label="Edad"
                valid-characters-only
                :rules="edad"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-text-field
                v-model="editedItem.nacionalidad"
                label="Nacionalidad"
                :rules="[v => !!v || 'Campo requerido']"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <vue-tel-input-vuetify
                v-model="editedItem.telefono"
                attach
                solo
                :max-len="15"
                label="Teléfono"
                placeholder="Teléfono"
                valid-characters-only
                mode="international"
                :rules="[v => !!v || 'Teléfono es requerido']"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <vue-tel-input-vuetify
                v-model="editedItem.telefono2"
                attach
                solo
                :max-len="15"
                label="Teléfono"
                placeholder="Teléfono"
                valid-characters-only
                mode="international"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-text-field
                v-model="editedItem.situacion_profesional"
                label="Situación Profesional"
                :rules="[v => !!v || 'Campo requerido']"
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="4"
            >
              <label
                class="red--text text-subtitle-2"
              >Anotaciones</label>
              <v-textarea
                v-model="editedItem.nota"
                name="input-7-1"
                label="Anotaciones"
                solo
                auto-grow
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <label>Situación Familiar</label>
              <v-textarea
                v-model="editedItem.situacion_familiar"
                name="input-7-1"
                label="Situación Familiar"
                solo
                :rules="[v => !!v || 'Campo requerido']"
                auto-grow
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <label>Formación</label>
              <v-textarea
                v-model="editedItem.formacion"
                name="input-7-1"
                label="Formación"
                solo
                :rules="[v => !!v || 'Campo requerido']"
                auto-grow
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-select
                v-model="editedItem.tipo_trabajo"
                :items="itemsTrabajo"
                label="Tipo de Trabajo"
                :rules="[v => !!v || 'Campo requerido']"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="editedItem.proyecto"
                label="Proyecto"
                :rules="[v => !!v || 'Campo requerido']"
              />
            </v-col>
            <v-dialog
              v-model="dialog"
              max-width="600"
            >
              <v-card>
                <v-card-title
                  class="text-h5"
                >
                  POLITICA TRATAMIENTO DE DATOS
                </v-card-title>
                <v-container fluid>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>
                        <b>Responsable:</b> ASOCIACIÓN CATÓLICA ESPAÑOLA DE SERVICIOS A LA JUVENTUD FEMENINA (ACISJF) <br>
                        <b>Finalidad:</b> Gestionar el Programa Integral de Inserción Laboral Individualizados y enviar comunicaciones sobre el mismo <br>
                        <b>Legitimación:</b> Ejecución de un contrato. Interés legítimo del Responsable. Cumplimiento de una obligación legal. <br>
                        <b>Destinatarios:</b> Están previstas cesiones de datos a: Empresas o personas físicas ofertantes de empleo; Federación ACISJF. <br>
                        <b>Derechos:</b> Tiene derecho a acceder, rectificar y suprimir los datos, así como otros derechos, indicados en la información adicional, que puede ejercer enviando un correo electrónico a <a href="mailto:DPO@lexforma.es">DPO@lexforma.es</a> o dirigiéndose a la dirección del responsable del tratamiento <br>
                        <b>Procedencia:</b> El propio interesado. <br>
                      </p>
                    </v-col>
                  </v-row>
                </v-container>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    class="mr-4"
                    color="primary"
                    @click="dialog = false"
                  >
                    Acepto
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-checkbox
                v-model="checkbox"
                :rules="[v => !!v || 'Debes estar de acuerdo!']"
                required
              >
                <template v-slot:label>
                  <div>
                    Estas de acuerdo?
                    <a
                      href="/#/admin/registros"
                      @click.stop="open"
                      v-on="on"
                    >
                      Ver la politica de datos.
                    </a>
                  </div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <label>Experiencia Profesional</label>
              <v-textarea
                v-model="editedItem.experiencia_profesional"
                name="input-7-1"
                label="Experiencia Profesional"
                solo
                :rules="[v => !!v || 'Campo requerido']"
                auto-grow
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <label>Referencias</label>
              <v-textarea
                v-model="editedItem.referencias"
                name="input-7-1"
                label="Referencias"
                solo
                :rules="[v => !!v || 'Campo requerido']"
                auto-grow
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-if="!mostrarFirmaWacom"
              cols="12"
              sm="12"
              md="6"
            >
              <span>Firma del usuario:</span>
              <VueSignaturePad
                ref="signaturePad"
                width="80%"
                height="300px"
                :custom-style="estilacho"
                class="firmavue"
              />
              <br>
              <div
                style="display: flex; justify-content: center;"
              >
                <v-btn
                  color="red"
                  @click="undo"
                >
                  Deshacer firma
                </v-btn>
              </div>
            </v-col>
            <v-col
              v-else
              id="firma"
              cols="12"
              sm="12"
              md="6"
            >
              <wacom-sdk
                class="my-20"
                :firma="editedItem.firma"
                @setFirmaWacom="getFirmaWacom"
              />
            </v-col>
            <v-col
              cols="5"
              sm="5"
              md="5"
            >
              <v-btn
                v-if="downloadFile"
                id="download"
                color="orange"
                class="mr-2"
                :loading="loadingDownload"
                style="margin-bottom: 5px;"
                @click="downloadFirma()"
              >
                Descargar firma
                <v-icon>
                  mdi-download
                </v-icon>
              </v-btn>
              <v-btn
                color="blue"
                @click="mostrarFirmaWacom = !mostrarFirmaWacom"
              >
                <span v-if="mostrarFirmaWacom">Firmar con mouse</span>
                <span v-else>Firmar con STU</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-file-input
                v-model="editedItem.file"
                label="Seleccione documento"
              />
              <v-text-field
                v-model="editedItem.nombre_documento"
                label="Nombre Documento"
              />
              <v-btn
                color="primary"
                class="mr-2"
                :loading="loadingDocumento"
                @click="cargarDocumento()"
              >
                Cargar Documento
                <v-icon
                  right
                  dark
                >
                  mdi-cloud-upload
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="9"
            >
              <v-card
                max-width="100%"
                min-height="100%"
                max-height="250px"
                class="mx-auto overflow-y-auto"
              >
                <v-toolbar
                  dark
                  dense
                >
                  <v-toolbar-title>Documentos cargados</v-toolbar-title>

                  <v-spacer />

                  <v-sheet
                    dark
                    class="text-caption"
                  >
                    Desplaza arriba/abajo para ver más documentos
                  </v-sheet>
                </v-toolbar>

                <v-list
                  v-if="editedItem.documentos !== undefined && editedItem.documentos.length > 0"
                  two-line
                >
                  <v-list-item
                    v-for="(item, i) in editedItem.documentos"
                    :key="i"
                  >
                    <v-list-item-action>
                      <v-btn
                        color="primary"
                        class="mr-2"
                        @click="downloadDocumento(item.id, item.nombre_documento, item.file)"
                      >
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title v-text="item.nombre_documento" />
                      <v-list-item-subtitle
                        class="text--primary"
                      />
                      <!-- <v-list-item-subtitle v-text="item.exp" /> -->
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn
                        color="error"
                        class="mr-2"
                        @click="removeFile(i, item.id)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <v-card-text v-else>
                  <p
                    class="text-center text-h3 mt-5 grey--text"
                  >
                    Aún no tenemos documentos cargados.
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <template>
              <v-tabs v-model="tab">
                <v-tab>Formación</v-tab>
                <v-tab>Intervención</v-tab>
                <v-tab>Intermediación Laboral</v-tab>
              </v-tabs>
              <v-tabs-items
                v-model="tab"
                class="expansionPanel"
              >
                <v-tab-item>
                  <formaciones
                    :edited-item-form="editedItemForms"
                    :usuaria-id="editedItem.id"
                    :formaciones-usuarias="formacion"
                    @submit-usuaria="submit"
                  />
                </v-tab-item>
                <v-tab-item>
                  <intervenciones
                    :edited-item-interv="editedItemInterv"
                    :usuaria-id="editedItem.id"
                    @submit-usuaria="submit"
                  />
                </v-tab-item>
                <v-tab-item>
                  <intermediaciones
                    :edited-item-interm="editedItemInterm"
                    :usuaria-id="editedItem.id"
                    :ofertas-usuarias="ofertas"
                    @submit-usuaria="submit"
                  />
                </v-tab-item>
              </v-tabs-items>
            </template>
          </v-row>
          <v-card-actions>
            <v-row>
              <v-spacer />
              <v-btn
                color="#F6F6F6"
                style="color:black;"
                @click="volver"
              >
                {{ $t("close") }}
              </v-btn>
              <v-btn
                class="mr-4"
                color="primary"
                :loading="loading"
                @click="submit"
              >
                {{ $t("save") }}
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-container>
    </template>
  </v-card>
</template>

<script>
  import formaciones from './formaciones.vue'
  import intervenciones from './intervenciones.vue'
  import intermediaciones from './intermediaciones.vue'
  import WacomSdk from '../../components/public/WacomSdk.vue'
  // import { VueTelInput } from 'vue-tel-input'
  import Vue from 'vue'
  export default {
    name: 'AddRegister',
    components: {
      formaciones,
      intervenciones,
      intermediaciones,
      WacomSdk,
      // VueTelInput,
    },
    props: {
      editedItem: {
        type: Object,
        required: true,
      },
      ofertas: {
        type: Array,
        required: true,
      },
      formacion: {
        type: Array,
        required: true,
      },
    },
    data: vm => {
      return {
        tab: null,
        on: false,
        valid: true,
        dateFn: new Date().toISOString().substr(0, 10),
        menuFn: false,
        search: '',
        snackbar: false,
        colorSnack: 'info',
        textSnack: '',
        dialog: false,
        checkbox: false,
        downloadFile: false,
        downloadFile2: false,
        timeout: 3000,
        estilacho: {
          border: 'black 3px solid',
          'margin-right': 'auto',
          'margin-left': 'auto',
        },
        clientes: [],
        itemsTrabajo: [
          'Externa',
          'Interna',
          'Interna/Externa',
        ],
        itemsDocumento: [
          { val: 'DNI', title: 'DNI' },
          { val: 'NIE', title: 'NIE' },
          { val: 'PS', title: 'PASAPORTE' },
          { val: 'NIEINT', title: 'NIE PT.INT' },
        ],
        headers: [
          {
            text: 'Fecha',
            align: 'left',
            sortable: true,
            value: 'fecha',
          },
          {
            text: 'Tipo ID',
            align: 'left',
            sortable: true,
            value: 'tipo',
          },
          {
            text: 'Identificación',
            align: 'left',
            sortable: true,
            value: 'identificacion',
          },
          {
            text: 'Nombre completo',
            align: 'left',
            sortable: true,
            value: 'nombre',
          },
          {
            text: 'Teléfono',
            align: 'left',
            sortable: true,
            value: 'telefono',
          },
          {
            text: 'Acciones',
            value: 'acciones',
            sortable: false,
          },
        ],
        emailRules: [
          v => !!v || 'EL correo electrónico es requerido',
          v => /.+@.+\..+/.test(v) || 'Ingresa un correo valido',
        ],
        edad: [
          v => {
            if (!isNaN(parseFloat(v)) && v >= 0) return true
            return 'Ingresa un número'
          },
        ],
        docNumberErrorMessages: null,
        editedItemForms: {
          id: null,
          tipo: '',
          idioma: '',
          fecha: '',
          descripcion: '',
        },
        editedItemInterv: {
          id: null,
          tipo: '',
          fecha: '',
          descripcion: '',
          entrevista: null,
          empleo: null,
        },
        editedItemInterm: {
          id: null,
          fecha: '',
          intermediacion: '',
        },
        mostrarFirmaWacom: true,
        firmaWacomBase64: null,
      }
    },
    computed: {
      registeradd: {
        get () {
          return this.$store.state.registros.registeradd
        },
      },

      loading: {
        get () {
          return this.$store.state.registros.loading
        },
      },
      loadingDownload: {
        get () {
          return this.$store.state.registros.loadingDownload
        },
      },
      loadingDownload2: {
        get () {
          return this.$store.state.registros.loadingDownload2
        },
      },
      loadingDocumento: {
        get () {
          return this.$store.state.registros.loadingDocumento
        },
      },
    },
    watch: {
      dateFn (val) {
        this.editedItem.fecha_inicio = this.formatDate(this.dateFn)
      },
      editedItem (newValue) {
        this.mostrarFirmaWacom = true // para que sea la opción que se habilite siempre por defecto

        if (newValue.id !== undefined && newValue.firma !== undefined && newValue.firma !== '') {
          this.checkbox = true
          this.downloadFile = true
          this.firmaWacomBase64 = this.editedItem.firma

          // Ahora pasan directo a la Wacom
          /* this.$nextTick(() => {
            this.$nextTick(() => {
              this.$refs.signaturePad.resizeCanvas()
              this.$refs.signaturePad.fromDataURL(this.editedItem.firma)
            })
          }) */
        } else {
          this.checkbox = false
          this.downloadFile = false
          this.firmaWacomBase64 = null
        }
      },
    },
    async beforeMount () {
      await this.$store.commit('registros/setRegister', false)
    },
    mounted () {
      this.$store.dispatch('ofertas/getOfertas')
      this.$store.dispatch('formaciones/getFormaciones')
    },
    methods: {
      open () {
        this.dialog = true
      },
      async downloadFirma () {
        var response = await this.$store.dispatch('registros/downloadFirma', this.editedItem.id)
        this.forceFileDownload(response, this.editedItem.nombre_apellido + '_firma.pdf')
      },
      undo () {
        this.$refs.signaturePad.undoSignature()
      },
      volver () {
        this.$refs.form.reset()
        this.$emit('initialize')
        this.$store.commit('registros/setRegister', false)
      },
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null
        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      forceFileDownload (response, nombre) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', nombre)
        document.body.appendChild(link)
        link.click()
      },
      async cargarDocumento () {
        const archivo = this.editedItem.file
        const documento = this.editedItem.nombre_documento
        if (!archivo) {
          Vue.notify({
            group: 'loggedIn',
            text: 'Debes seleccionar un documento',
            type: 'red accent-2',
            duration: 5000,
          })
          return
        }

        if (!documento) {
          Vue.notify({
            group: 'loggedIn',
            text: 'Coloque el nombre del documento',
            type: 'red accent-2',
            duration: 5000,
          })
          return
        }

        if (!this.editedItem.id) {
          // Si la usuaria no existe, se invoca el método para crearla
          await this.submit()

          this.$store.commit('registros/setLoaderDocumento')

          setTimeout(() => {
            if (!this.editedItem.id) {
              this.$store.commit('registros/setLoaderDocumento')
              Vue.notify({
                group: 'loggedIn',
                text: 'No ha sido posible dar de alta a la usuaria',
                type: 'red accent-2',
                duration: 5000,
              })
            } else {
              this.completarCargaDocumento()
            }
          }, 3000)
        } else {
          this.$store.commit('registros/setLoaderDocumento')
          this.completarCargaDocumento()
        }
      },
      completarCargaDocumento () {
        const formData = new FormData()
        formData.append('_file', this.editedItem.file)
        formData.append('nombre_documento', this.editedItem.nombre_documento)
        formData.append('id', this.editedItem.id)

        this.$store.dispatch('registros/addDocumentoUsuaria', formData)
          .then((response) => {
            Vue.notify({
              group: 'loggedIn',
              text: response.data.success,
              type: 'teal accent-3',
              duration: 5000,
            })

            if (!this.editedItem.documentos) {
              this.editedItem.documentos = []
            }

            this.editedItem.documentos.push({
              id: response.data.id,
              nombre_documento: response.data.nombre,
              file: response.data.file,
            })

            this.editedItem.file = null
            this.editedItem.nombre_documento = null
          })
          .catch(err => {
            // eslint-disable-next-line
            console.log(err)
            Vue.notify({
              group: 'loggedIn',
              text: 'No se pudo guardar en este momento',
              type: 'red accent-2',
              duration: 5000,
            })
          })
      },
      async downloadDocumento (i, name, file) {
        var extension = file.split('.')
        var response = await this.$store.dispatch('registros/downloadDocumentUsuaria', i)
        this.forceFileDownload(response, name + '.' + extension[1])
      },
      removeFile (i, item) {
        const index = i
        if (confirm('¿Realmente desea eliminar este registro?')) {
          this.$store.dispatch('registros/deleteDocumentoUsuaria', item)
            .then((response) => {
              Vue.notify({
                group: 'loggedIn',
                text: response.data.success,
                type: 'teal accent-3',
                duration: 5000,
              })
              this.editedItem.documentos.splice(index, 1)
            })
            .catch(err => {
              // eslint-disable-next-line
              console.log(err)
              Vue.notify({
                group: 'loggedIn',
                text: 'Lo sentimos, el documento no puede ser eliminado en este momento',
                type: 'red accent-2',
                duration: 5000,
              })
            },
            )
        }
      },
      docNumberRule (value, docType) {
        if (
          value !== undefined &&
          value !== '' &&
          docType === 'DNI'
        ) {
          const isValid = this.validateDni(value)
          if (!isValid) {
            this.docNumberErrorMessages = 'Formato de DNI inválido'
            return false
          }
        }
        if (
          value !== undefined &&
          value !== '' &&
          docType === 'NIE'
        ) {
          const isValid = this.validateNie(value)
          if (!isValid) {
            this.docNumberErrorMessages = 'Formato de NIE inválido'
            return false
          }
        }
        if (
          this.docNumberErrorMessages === 'Formato de DNI inválido' ||
          this.docNumberErrorMessages === 'Formato de NIE inválido'
        ) {
          this.docNumberErrorMessages = null
        }
        return true
      },
      validateDocument () {
        // Ejecutar todas las rules que tenga en el input con ref = docNumber
        this.$refs.docNumber.resetValidation()
      },
      /**
       * Lógica de validación de DNI español
       * Serán válidas estas dos variantes: 02766275L | 02766275-L
       */
      validateDni (dni) {
        const dniRegex = /^(\d{8}[A-Z]|\d{8}-[A-Z])$/i
        return dniRegex.test(dni)
      },
      /**
       * Lógica de validación de NIE español
       * Serán válidas estas dos variantes: Y2197712F | Y2197712-F
       */
      validateNie (nie) {
        const nieRegex = /^([XYZ]\d{7}[A-Z]|([XYZ]\d{7})-[A-Z])$/i
        return nieRegex.test(nie)
      },
      validateUserExist (/* tipo, */ dni) {
        if (/* tipo && */ dni) {
          const datos = {
            // tipo: tipo,
            dni: dni,
          }
          this.$store.dispatch('registros/getregistrosByTipoId', datos)
            .then((response) => {
              // si no es de la propia usuaria
              if (response.data.data[0].id !== this.editedItem.id) {
                this.docNumberErrorMessages = response.data.message
                Vue.notify({
                  group: 'loggedIn',
                  text: response.data.message,
                  title: 'Ya existe',
                  type: 'red accent-3',
                  duration: 5000,
                })
              } else {
                this.docNumberErrorMessages = null
              }
            })
            .catch(err => {
              // eslint-disable-next-line
              console.log(err)
              this.docNumberErrorMessages = null
              Vue.notify({
                group: 'loggedIn',
                text: err.response.data.message,
                title: 'Nueva usuaria',
                type: 'teal accent-5',
                duration: 5000,
              })
            })
        }
      },
      getFirmaWacom (firma) {
        this.firmaWacomBase64 = firma
        // console.log(this.firmaWacomBase64)
      },
      submit () {
        localStorage.removeItem('usuariaId')
        if (
          this.$refs.form.validate() &&
          this.docNumberErrorMessages === null
        ) {
          if (this.mostrarFirmaWacom === false && this.$refs.signaturePad === undefined) {
            Vue.notify({
              group: 'loggedIn',
              text: 'Antes debe ingresar una firma',
              type: 'info accent-3',
              duration: 5000,
            })
            return
          }

          if (this.mostrarFirmaWacom === true && this.firmaWacomBase64 === null) {
            Vue.notify({
              group: 'loggedIn',
              text: 'Antes debe ingresar una firma y presionar el botón Confirmar',
              type: 'info accent-3',
              duration: 5000,
            })
            return
          }

          let firmaUsuario = null

          if (this.mostrarFirmaWacom === false) {
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature()

            if (isEmpty) {
              Vue.notify({
                group: 'loggedIn',
                text: 'Antes debe ingresar una firma',
                type: 'info accent-3',
                duration: 5000,
              })
              return
            } else {
              firmaUsuario = data
            }
          } else {
            firmaUsuario = this.firmaWacomBase64
          }

          const datos = {
            id: this.editedItem.id,
            fecha_inicio: this.editedItem.fecha_inicio,
            nombre_apellido: this.editedItem.nombre_apellido,
            tipo_documento: this.editedItem.tipo_documento,
            nro_documento: this.editedItem.nro_documento,
            nacionalidad: this.editedItem.nacionalidad,
            telefono: this.editedItem.telefono,
            telefono2: this.editedItem.telefono2,
            telefono3: this.editedItem.telefono3,
            edad: this.editedItem.edad,
            situacion_familiar: this.editedItem.situacion_familiar,
            formacion: this.editedItem.formacion,
            nota: this.editedItem.nota,
            situacion_profesional: this.editedItem.situacion_profesional,
            tipo_trabajo: this.editedItem.tipo_trabajo,
            proyecto: this.editedItem.proyecto,
            experiencia_profesional: this.editedItem.experiencia_profesional,
            referencias: this.editedItem.referencias,
            firma: firmaUsuario,
          }

          if (!this.editedItem.id) {
            this.$store.dispatch('registros/addregistros', datos)
              .then((response) => {
                this.editedItem.id = response.data.data.id
                localStorage.setItem('usuariaId', this.editedItem.id)
                Vue.notify({
                  group: 'loggedIn',
                  text: response.data.success,
                  type: 'teal accent-3',
                  duration: 5000,
                })
              })
              .catch(err => {
                // eslint-disable-next-line
                    console.log(err)
                Vue.notify({
                  group: 'loggedIn',
                  text: 'No se pudo guardar en este momento',
                  type: 'red accent-2',
                  duration: 5000,
                })
              })
          } else {
            this.$store.dispatch('registros/updateregistros', datos)
              .then((response) => {
                this.$emit('initialize')
                Vue.notify({
                  group: 'loggedIn',
                  text: response.data.success,
                  type: 'teal accent-3',
                  duration: 5000,
                })
              })
              .catch(err => {
                // eslint-disable-next-line
                console.log(err)
                Vue.notify({
                  group: 'loggedIn',
                  text: 'No se pudo guardar en este momento',
                  type: 'red accent-2',
                  duration: 5000,
                })
              },
              )
          }
        } else {
          Vue.notify({
            group: 'loggedIn',
            text: 'Verifique que brindó la información básica para crear una usuaria',
            type: 'red accent-2',
            duration: 5000,
          })
        }
      },
    },
  }
</script>

<style scoped>
.v-card {
  margin-top: 0px;
  margin-bottom: 0px;
}
.v-card__title {
  background-color: aliceblue;
}
.expansionPanel {
  width: 100%;
}
.vue-tel-input {
  height: 50px;
  border: none;
  border-bottom: 1px solid #bbb;
}
#firma{
  display: flex;
  position: relative;
}

#firma div{
  width: 100%;
}

.firmavue{
  display: flex;

}

.btnFirmMouse{
  position: absolute;
  margin-bottom: 12px;
  bottom: 0;
  right: 0;
  margin-bottom: 20px;
}

</style>
